<script lang="ts">
	import { DataHandlerDevice, DataHandlerSnapshot, DeviceGroup } from "luxedo-data"
	import { getCurrentUser } from "../../../../../stores/UserStore"
	import { CollapsibleSection } from "svelte-comps/comps"
	import { ArrowDown } from "svelte-comps/icons"

	type ResolutionList = Array<{ x: number; y: number }>

	export let selectedSnapshotId: number
	export let selectedDeviceId: number

	let suggestedResolutions: ResolutionList = []

	function updateResolution() {
		suggestedResolutions = []
		const device = DataHandlerDevice.get(selectedDeviceId)

		if (device instanceof DeviceGroup) {
			suggestedResolutions.push({
				x: device.resX,
				y: device.resY,
			})

			if (getCurrentUser().hasPriv("commercial")) {
				suggestedResolutions.push({
					x: Math.ceil(device.resX / 2),
					y: Math.ceil(device.resY / 2),
				})
				suggestedResolutions.push({
					x: Math.ceil(device.resX / 4),
					y: Math.ceil(device.resY / 4),
				})
			}
		} else {
			const snapshot = DataHandlerSnapshot.get(selectedSnapshotId)

			suggestedResolutions.push({
				x: snapshot.resolution.w,
				y: snapshot.resolution.h,
			})
		}
	}

	function renderResolutions(resList: ResolutionList) {
		let str = ""
		for (let i = 0; i < resList.length; i++) {
			const res = resList[i]

			let seperator = ", "
			if (i === resList.length - 1) seperator = " or "
			else if (i === 0) seperator = ""

			str = `${str}${seperator}${res.x}x${res.y}`
		}
		return str
	}

	$: (selectedSnapshotId || selectedDeviceId) && updateResolution()
</script>

<div class="flex-column">
	<h3>Export your project</h3>
	<p>Export your project, making sure that:</p>
	<ul>
		<li>Your snapshot image has been hidden before rendering your scene.</li>
		<li>
			The scene is being exported to the <span class="highlight">MP4</span> or
			<span class="highlight">MOV</span> format.
		</li>
		{#if suggestedResolutions.length === 1}
			<li>
				Your scene is being exported to the correct resolution <span class="highlight"
					>({suggestedResolutions[0].x ?? "0"}x{suggestedResolutions[0].y ?? "0"})</span
				>.
			</li>
		{:else}
			<li>
				Your scene is being exported to a valid resolution matching the aspect ratio <span
					class="highlight">({renderResolutions(suggestedResolutions)})</span
				>.
				<span style="font-weight: bold;"
					>The aspect ratio must match the original resolution and be no less than 1/4 of the
					resolution.</span
				>
			</li>
		{/if}
	</ul>

	<p>Once exported, move on to the next step to upload.</p>
	<p>If you are working with a professional, you can skip this step.</p>
	<br />
	<CollapsibleSection>
		<div slot="header" class="more-info-heading">
			<span> More info </span>
			<ArrowDown />
		</div>
		<div class="more-info" slot="content">
			<p>Supported codecs:</p>
			<ul>
				<li>MP4: H.264, H.265, VP9</li>
				<li>MOV: Apple ProRes</li>
				<li>MKV/AV1: coming soon!</li>
			</ul>
			<br />
		</div>
	</CollapsibleSection>
</div>

<style>
	p {
		margin: 1rem 0 0 0;
		color: var(--color-text);
	}

	.more-info p {
		margin-top: 0;
	}

	.highlight {
		color: var(--color-main);
	}

	ul {
		list-style: disc;
		margin-left: 1rem;
		color: var(--color-text);
	}

	.more-info-heading {
		display: flex;
		flex-direction: row;
		align-items: center;
		font-size: var(--h1);
		font-family: var(--font-heading);
	}

	.more-info-heading :global(svg) {
		height: 2rem;
		width: auto;
		transition: transform 250ms;
	}

	:global(.section-expanded) .more-info-heading :global(svg) {
		transform: rotateX(180deg);
	}

	.more-info-heading :global(.svg-stroke) {
		stroke: var(--color-main);
	}
</style>
