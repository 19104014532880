<script lang="ts">
	import Dropzone from "dropzone"
	import "dropzone/dist/basic.css"

	import { LuxedoRPC } from "luxedo-rpc"
	import { onMount } from "svelte"
	import { closeOverlay } from "../overlay"
	import { Toast } from "../toaster"

	import LoadingSpinner from "../loading-indicator/LoadingSpinner.svelte"
	import UploadIcon from "../icons/UploadIcon.svelte"

	export let placeholder: string =
		"Click to browse or drag files here to upload to your media library."
	export let hideButton: boolean = false

	export let removeFilesizeRestriction: boolean = false
	export let uploadButtonId: string = undefined
	export let uploadMultiple: boolean = false
	export let uploadUrl

	export let acceptedFiles = [
		".jpeg",
		".jpg",
		".mp3",
		".png",
		".gif",
		".mp4",
		".webm",
		".mov",
		".wav",
		".mpeg",
	]

	export let onSuccess: (file: Dropzone.DropzoneFile) => void = () => {}
	export let onFail: (
		file: Dropzone.DropzoneFile,
		message: string | Error,
		xhr: XMLHttpRequest
	) => void = () => {}

	export let onDropzoneSend: (
		file: Dropzone.DropzoneFile,
		xhr: XMLHttpRequest,
		formData: FormData
	) => void = () => {}

	/**
	 * Called when user presses upload button.
	 * Should handle upload and processing.
	 */
	export let onUpload: (
		files: Dropzone.DropzoneFile | Array<Dropzone.DropzoneFile>,
		name: string,
		dropzone: Dropzone
	) => Promise<void>

	export let triggerUpload = () => {
		if (fileList.length <= 0) return Toast.error("You must select a file to upload.")
		isLoading = true
		onUpload(uploadMultiple ? fileList : fileList[0], fileName, dropzone)
	}

	export const reset = () => {
		canUpload = false

		dropzone.removeAllFiles()
		fileList = []
	}

	export let isLoading: boolean = false
	export let dropzone: Dropzone = undefined

	/**
	 * Called on file select - can selectively cancel or remove files here
	 */
	function onAccept(file: Dropzone.DropzoneFile, done: (error?: string | Error) => void) {
		dropzone.options.autoProcessQueue = false
		fileList.push(file)
		canUpload = true
		if (fileList.length > 1) {
			if (!uploadMultiple) dropzone.removeFile(file)
			else {
				hideNameInput = true
			}
		} else {
			hideNameInput = false
		}

		done()
	}

	/**
	 * Called on file select after a thumbnail has been created
	 * TODO - apply thumbnail images
	 */
	function showThumbnail(file: Dropzone.DropzoneFile, dataUrl: string) {
		console.log("tn", { file, dataUrl })
	}

	function handleFail(file: Dropzone.DropzoneFile, message: string | Error, xhr: XMLHttpRequest) {
		reset()
		onFail(file, message, xhr)
	}

	onMount(() => {
		// Create dropzone object
		dropzone = new Dropzone(`#media-upload`, {
			paramName: "file",
			maxFiles: uploadMultiple ? 10 : 1,
			maxFilesize: removeFilesizeRestriction ? 1000000000 : 6000,
			withCredentials: true,
			createImageThumbnails: true,
			acceptedFiles: acceptedFiles.join(", "),
			url: uploadUrl,
			headers: { "X-CSRF-TOKEN": LuxedoRPC.getCsrfToken() },
			autoProcessQueue: false,
			chunking: true,
			forceChunking: true,
			accept: onAccept,
			sending: onDropzoneSend,
			error: handleFail,
			success: onSuccess,
			processing: function () {
				this.options.autoProcessQueue = true
			},
			init: function () {},
		})
	})

	let fileList: Array<Dropzone.DropzoneFile> = []
	export let canUpload: boolean = false // shows upload button
	let hideNameInput: boolean = false // used if user is uploading multiple files
	let fileName: string
</script>

<form id={`media-upload`} class="dropzone">
	{#if !canUpload}
		<div class="empty-media-container">
			<UploadIcon />
			<p>{placeholder}</p>
		</div>
	{/if}
</form>

{#if !hideButton}
	<div class="button-container">
		<button class="link-button" on:click={() => closeOverlay()}>Cancel</button>
		{#if isLoading}
			<LoadingSpinner height="1.75rem" color="var(--color-main)" />
		{:else}
			<button
				id={uploadButtonId ? uploadButtonId : ""}
				on:click={triggerUpload}
				disabled={!canUpload}>Upload</button
			>
		{/if}
	</div>
{/if}

<style>
	form {
		width: 100%;
		margin-bottom: 1rem;
	}

	.dropzone {
		width: 100%;
		height: 100%;
		min-height: 12rem;
		border-radius: var(--br);
		background-color: var(--color-bg-dark-transparent);
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 4rem 3rem;
		margin-bottom: 1rem;
		flex-wrap: wrap;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.button-container :global(.loader) {
		margin: 0 1.7rem;
	}

	button {
		width: 100%;
		max-width: 24rem;
		text-align: center;
	}

	.dropzone :global(.dz-button) {
		display: none;
		transform: unset;
		color: var(--color-text-light);
		box-shadow: unset;
		position: absolute;
		bottom: 1rem;
		left: 0;
		right: 0;
		margin-right: auto;
		margin-left: auto;

		transition:
			color var(--transition-duration),
			background-color var(--transition-duration);
	}

	.dropzone :global(.dz-button:hover),
	.dropzone :global(.dz-button:focus-visible) {
		color: var(--color-text);
	}

	.dropzone :global(.dz-error-message) {
		background-color: var(--color-bg-dark);
		padding: 0.25rem;
		border-radius: var(--br);
		margin-top: 0.5rem;
		font-size: var(--text-small);
	}

	.dropzone :global(.dz-details) {
		font-size: var(--text-small);
		line-height: 1rem;
		padding-bottom: 0.25rem;
	}

	.dropzone :global(.dz-preview) {
		overflow: hidden;
		box-shadow: var(--shadow-medium);
	}

	.dropzone :global(.dz-preview .dz-progress .dz-upload) {
		background-color: var(--color-main);
		border-radius: var(--br);
	}

	.dropzone :global(.dz-progress) {
		border-radius: var(--br);
		overflow: hidden;
		height: 0.5rem;
		border: none;
		background-color: var(--color-bg);
	}

	.dropzone :global(.dz-file-preview) {
		background-color: var(--color-bg-dark);
		border-radius: var(--br);
		padding: 0.5rem;
		aspect-ratio: 1/1;
	}

	.dropzone :global(.dz-size) {
		color: var(--color-text);
		font-size: var(--h3);
	}

	.dropzone :global(.dz-filename) {
		margin-bottom: 0.5rem;
	}

	.dropzone :global(.dz-image-preview) {
		aspect-ratio: 1/1;

		background-color: var(--color-bg-dark);
		border-radius: var(--br);
		padding: 0;
		overflow: hidden;
	}

	.dropzone :global(.dz-preview .dz-image) {
		display: flex;
	}

	.dropzone :global(.dz-image-preview .dz-image) {
		height: fit-content;
	}

	.dropzone :global(.dz-image-preview .dz-details) {
		display: none;
	}

	.dropzone :global(.dz-file-preview .dz-progress),
	.dropzone :global(.dz-image-preview .dz-progress) {
		position: absolute;
		width: 90%;
		left: 5%;
		bottom: 0.25rem;
	}

	.button-container {
		align-self: flex-end;
		width: fit-content;
	}

	.link-button {
		width: fit-content;
	}

	.empty-media-container {
		user-select: none;
		pointer-events: none;
		display: flex;
		flex-direction: column;
		align-items: center;
		color: var(--color-text);
	}

	.empty-media-container > :global(svg) {
		width: 25%;
	}

	.empty-media-container > p {
		width: 75%;
		text-align: center;
		margin-top: 0.5rem;
		line-height: 1.25em;
	}
</style>
