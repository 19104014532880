<script lang="ts">
	import type { Device } from "luxedo-data"
	import { ToggleSwitch } from "svelte-comps/inputs"
	import { SelectedDeviceStore } from "../../../../../stores/SelectedDeviceStore"
	import { DeviceSpotlightManager } from "luxedo-data"
	import { CalibrationOverlayController } from "../CalibrationOverlayController"
	import { LocationUtils } from "../../../../../modules/locationUtils"
	import { openConfirmOverlay } from "svelte-comps/overlay"
	import type { ConfirmInputOptions } from "svelte-comps/overlay"

	let device: Device

	let togglingSpotlight = false
	let spotlightActive = false

	SelectedDeviceStore.subscribe((dev) => (device = dev))
	DeviceSpotlightManager.subscribe((ctx) => (spotlightActive = ctx[device.id]))

	async function beginCalibration() {
		// Check if it's daylight and get user confirmation
		const isDark = await confirmIsDark()
		if (!isDark) return cancelCalibration()

		if (spotlightActive) {
			await disableSpotlight()
		}

		await CalibrationOverlayController.calibrate()
	}

	/**
	 * Check if it's daylight and get user confirmation
	 * Resolves with a true/false value if the scene is dark
	 */
	async function confirmIsDark(spoofedLocation?: LocationUtils.Location): Promise<boolean> {
		const location = spoofedLocation ?? (await LocationUtils.getLocation())

		// If we have NO location (i.e., null), fall back to standard day/night times:
		if (!location) {
			const now = new Date()
			const hour = now.getHours() // 0–23

			// Standard day: 6 AM <= hour < 20 (8 PM)
			const isFallbackDay = hour >= 6 && hour < 20

			// If it's not day, it must be dark, so immediately return true (dark = proceed)
			if (!isFallbackDay) return true

			// If it is day, show the same warning popup as before
			const fallbackMessage = `
				Warning: Based on the time of the day, we detected that it might be daylight at your location.
				Calibration will not work if it is too bright.
			`

			return new Promise<boolean>((resolve) => {
				const options: ConfirmInputOptions = {
					prompt: fallbackMessage,
					buttons: {
						confirm: {
							text: "My location is dark",
							onClick: () => resolve(true),
						},
						deny: {
							text: "I will try again when it is dark",
							onClick: () => resolve(false),
						},
					},
				}
				openConfirmOverlay(options, {
					heading: "Possible Daylight Detected",
					classHeading: "no-underline",
				})
			})
		}

		// ===== If we DO have a location, use the real isDaylight() logic =====
		const isDay = await LocationUtils.isDaylight(location, new Date())
		if (!isDay) return true

		// Otherwise, show the confirmation overlay
		return new Promise<boolean>((res) => {
			const option: ConfirmInputOptions = {
				prompt: [
					"Warning: We detected that it might be daylight at your location.",
					"Calibration will not work if it is too bright in your location.",
				],
				buttons: {
					confirm: {
						text: "My location is dark",
						onClick: () => res(true),
					},
					deny: {
						text: "I will try again when it is dark",
						onClick: () => res(false),
					},
				},
			}
			openConfirmOverlay(option, { heading: "Daylight Detected" })
		})
	}

	function cancelCalibration() {
		CalibrationOverlayController.cancel()
	}

	async function activateSpotlight() {
		togglingSpotlight = true
		await DeviceSpotlightManager.activateSpotlight(device)
		togglingSpotlight = false
	}

	async function disableSpotlight() {
		togglingSpotlight = true
		await DeviceSpotlightManager.deactivateSpotlight(device)
		togglingSpotlight = false
	}

	async function toggleSpotlight() {
		if (spotlightActive) {
			return await disableSpotlight()
		} else {
			return await activateSpotlight()
		}
	}
</script>

<div id="calibration-instructions">
	<div class="calibration-step">
		<span class="step_number">1</span>
		Make sure your device is in position before starting calibration process.
	</div>

	<div class="calibration-step">
		<span class="step_number">2</span>
		Ensure your projection surface is protected from any interfering light sources such as the sun, christmas
		lights or lights inside your home.
	</div>

	<div class="calibration-step">
		<span class="step_number">3</span>
		Do not block or stand in front of the device during the calibration process.
	</div>

	<div class="advanced-option-spotlight">
		<span class="label">Enable Projector Spotlight </span>
		<ToggleSwitch
			isActive={spotlightActive}
			disabled={togglingSpotlight}
			onUpdate={() => toggleSpotlight()}
			info="Enabling the projector spotlight will trigger your device to project white light allowing you to better visualize and adjust your device's position."
		/>
	</div>
</div>

<div class="button-container">
	<button on:click={cancelCalibration} class="link-button">Cancel</button>
	<button on:click={beginCalibration}>Calibrate</button>
</div>

<style>
	.step_number {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		min-width: 3rem;
		width: 3rem;
		height: 3rem;
		border: 1px solid var(--color-text);
		color: var(--color-text-light);

		border-radius: 99px;
		margin-right: 1rem;
		padding-bottom: 0.5rem;
		font-size: 2rem;
	}

	.calibration-step {
		margin-bottom: 1rem;
		display: flex;
		flex-direction: row;
		color: var(--color-text);
		align-items: center;
	}

	#calibration-instructions {
		max-width: 36rem;
	}

	.advanced-option-spotlight {
		position: absolute;
		top: -2.25rem;
		right: 1rem;
		color: var(--color-text);
		display: flex;
		flex-direction: row;
		align-items: center;
		z-index: 15;
	}

	.advanced-option-spotlight .label {
		font-size: var(--h3);
		color: var(--color-text);
		line-height: 1em;
		margin-right: 0.5rem;
	}
</style>
